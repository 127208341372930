import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src='200x200.png'  alt="logo" />{/*className="App-logo"*/}
        <h1>
          <b>Actuación Completa</b>
        </h1>
        <p>Estamos realizando mantenimiento para mejorar la experiencia.</p>
      </header>
    </div>
  );
}

export default App;
